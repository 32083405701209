import { TextField } from "@mui/material";
import { Form } from "@rjsf/mui";
import validator from '@rjsf/validator-ajv8';
import { JSONSchema7 } from "json-schema";
import { Component, ReactNode } from "react";
import Markdown from "react-markdown";
import { ParameterType } from "../../api/dtos/parameter.interface";


export class MessageBubble extends Component {

    constructor(readonly props: {
        content: string | { [key: string]: any },
        contentFormat?: JSONSchema7,
        onAssistantMessageChange: (value: string | { [key: string]: any }) => void,
        isStreaming: boolean,
        editable: boolean,
    }) {
        super(props);
    }

    render(): ReactNode {

        // console.log(this.props.content);
        // console.log(JSON.stringify(this.props.contentFormat, null, 2));

        const modifySchemaForForm = (schema: JSONSchema7) => {
            if (schema.type === ParameterType.OBJECT || (schema.type === 'array' && schema.items && (schema.items as JSONSchema7).type === ParameterType.OBJECT)) {
                Object.entries(schema.properties ?? (schema.items as JSONSchema7)?.properties ?? {}).forEach(([name, property]) => {
                    let type = (property as JSONSchema7).type === 'array' ? ((property as JSONSchema7).items as JSONSchema7)?.type as ParameterType : (property as JSONSchema7).type as ParameterType;
                    if (type === 'string' && (property as JSONSchema7).format === 'date-time') {
                        type = ParameterType.DATE;
                    }

                    if (!type && ((property as JSONSchema7).oneOf)) {
                        (property as JSONSchema7).type = 'string';
                    } else if (!type && ((property as JSONSchema7).items as JSONSchema7)?.oneOf) {
                        ((property as JSONSchema7).items as JSONSchema7).type = 'string';
                    }
                });
            } else {
                throw new Error('Invalid schema');
            }
        }

        if (this.props.contentFormat) {
            modifySchemaForForm(this.props.contentFormat);
        }

        return (
            <div className='MessageBubble'>
                {this.props.editable && this.props.contentFormat ? (
                    <Form
                        schema={this.props.contentFormat}
                        uiSchema={{
                            "ui:submitButtonOptions": { norender: true },
                        }}
                        formData={this.props.content}
                        validator={validator}
                        onChange={(data) => this.props.onAssistantMessageChange(data.formData)}
                    />
                ) : this.props.editable && !this.props.contentFormat ? (
                    <TextField
                        multiline
                        fullWidth
                        value={typeof this.props.content === 'string' ? this.props.content : JSON.stringify(this.props.content, null, 2)}
                        variant="outlined"
                        minRows={3}
                        onChange={(e) => this.props.onAssistantMessageChange(e.target.value)}
                    />
                ) : (
                    <Markdown>{typeof this.props.content === 'string' ? this.props.content : ('```json\n' + JSON.stringify(this.props.content, null, 2) + (this.props.isStreaming ? '\n...' : '') + '\n```')}</Markdown>
                )}
            </div>
        );

    }
}