import { Delete, Refresh } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Link, MenuItem, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { JSONParser } from '@streamparser/json';
import { JSONSchema7 } from 'json-schema';
import React, { useState } from 'react';
import { ParameterDto, ParameterRagType, ParameterType } from '../../api/dtos/parameter.interface';
import { ToolbaseApi } from '../../api/toolbase.api';
import { ParameterUtil } from '../../api/util';


interface ParameterEditorInnerProps {
    initialParameters: ParameterDto[];
    onChange: (parameters: ParameterDto[]) => void;
    isInput: boolean;
    parentParameter?: ParameterDto;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ParameterEditorInner: React.FC<ParameterEditorInnerProps> = (({ initialParameters = [], onChange, isInput, parentParameter }) => {

    const handleAddParameter = () => {
        const newParameter: ParameterDto = { name: '', description: '', type: ParameterType.STRING, isRequired: false, isArray: false, ragType: ParameterRagType.NONE };
        const newParameters = [...initialParameters, newParameter];
        onChange(newParameters);

    };

    const handleParameterChange = (index: number, updatedParameter: Partial<ParameterDto>) => {
        const newParameters = [...initialParameters];
        newParameters[index] = { ...newParameters[index], ...updatedParameter };
        if (newParameters[index].type !== ParameterType.OBJECT && newParameters[index].type !== ParameterType.ENUM) {
            delete newParameters[index].subProperties;
        }
        onChange(newParameters);
    };

    const handleRemoveParameter = (index: number) => {
        const newParameters = initialParameters.filter((_, i) => i !== index);
        onChange(newParameters);
    };

    const handleSubParameterChange = (index: number, subParameters: ParameterDto[]) => {
        const newParameters = [...initialParameters];
        newParameters[index].subProperties = subParameters;
        onChange(newParameters);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell width="200px">Name</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell width="160px">Type</StyledTableCell>
                            <StyledTableCell width="80px" align="center">Required</StyledTableCell>
                            <StyledTableCell width="80px" align="center">List</StyledTableCell>
                            {isInput && <StyledTableCell width="105px" align="center">RAG</StyledTableCell>}
                            <StyledTableCell width="80px" align="center">Actions</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {initialParameters.map((parameter, index) => (
                            <React.Fragment key={index}>
                                <StyledTableRow>
                                    <StyledTableCell width="200px">
                                        <TextField
                                            fullWidth
                                            value={parameter.name}
                                            onChange={(e) => handleParameterChange(index, { name: e.target.value })}
                                            placeholder={`Parameter ${index + 1}`}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            fullWidth
                                            value={parameter.description}
                                            onChange={(e) => handleParameterChange(index, { description: e.target.value })}
                                            placeholder="Description"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width="160px">
                                        <TextField
                                            select
                                            fullWidth
                                            value={parameter.type && parameter.type.length > 0 ? parameter.type : ParameterType.STRING}
                                            onChange={(e) => handleParameterChange(index, { type: e.target.value as ParameterType, ...(e.target.value as ParameterType !== ParameterType.STRING ? { ragType: ParameterRagType.NONE } : {}) })}
                                        >
                                            {(parentParameter?.type === ParameterType.ENUM ? [ParameterType.STRING] : Object.values(ParameterType)).map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell width="80px" align="center">
                                        <Checkbox
                                            checked={parameter.isRequired}
                                            disabled={parentParameter?.type === ParameterType.ENUM}
                                            onChange={(e) => handleParameterChange(index, { isRequired: e.target.checked })}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell width="80px" align="center">
                                        <Checkbox
                                            checked={parameter.isArray}
                                            disabled={parentParameter?.type === ParameterType.ENUM}
                                            onChange={(e) => handleParameterChange(index, { isArray: e.target.checked })}
                                        />
                                    </StyledTableCell>
                                    {isInput && <StyledTableCell width="105px" align="center">
                                        <TextField
                                            select
                                            fullWidth
                                            disabled={parentParameter?.type === ParameterType.ENUM}
                                            value={parameter.type === ParameterType.STRING && parentParameter?.type !== ParameterType.ENUM ? (parameter.ragType ?? ParameterRagType.NONE) : ParameterRagType.NONE}
                                            onChange={(e) => handleParameterChange(index, { ragType: e.target.value as ParameterRagType })}
                                        >
                                            {(parameter.type === ParameterType.STRING ? Object.values(ParameterRagType) : [ParameterRagType.NONE]).map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </StyledTableCell>}
                                    <StyledTableCell width="80px" align="center">
                                        <IconButton onClick={() => handleRemoveParameter(index)} color="error" size="small">
                                            <Delete />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {(parameter.type === ParameterType.OBJECT || parameter.type === ParameterType.ENUM) && (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={6}>
                                            <Box sx={{ pl: 4 }}>
                                                <ParameterEditorInner
                                                    initialParameters={parameter.subProperties ?? []}
                                                    onChange={(subParameters) => handleSubParameterChange(index, subParameters)}
                                                    isInput={isInput}
                                                    parentParameter={parameter}
                                                />
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleAddParameter} variant="contained" color="primary">
                    Add Parameter
                </Button>
            </Box>
        </Box>
    );
});

interface ParameterEditorProps {
    initialParameters?: ParameterDto[];
    onClose: (parameters: ParameterDto[] | undefined) => void;
    isInput: boolean;
}

const ParameterEditor: React.FC<ParameterEditorProps> = ({ initialParameters, onClose, isInput }) => {
    const [initialParametersState, setInitialParametersState] = useState<ParameterDto[] | undefined>(initialParameters);
    const [isGenerating, setIsGenerating] = useState(false);



    return (
        <Dialog
            open={true}
            onClose={() => isGenerating ? undefined : onClose(initialParametersState)}
            maxWidth="xl"
            fullWidth
            className='parameter-editor'
        >
            <DialogTitle>
                {isInput ? 'Input Parameters' : 'Output Parameters'}
                {initialParametersState && initialParametersState.length > 0 && <Link
                    component="button"
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(ParameterUtil.parametersToJSONSchema(initialParametersState ?? []), null, 2));
                        alert('Copied to clipboard');
                    }}
                    sx={{
                        float: 'right', fontSize: '.9rem', color: 'gray',
                        display: initialParametersState ? 'block' : 'none'
                    }}
                >
                    Copy JSON Schema
                    <Refresh sx={{
                        display: isGenerating ? 'block' : 'none',
                        animation: isGenerating ? 'spin 0.5s linear infinite' : 'none',
                        '@keyframes spin': {
                            from: { transform: 'rotate(0deg)' },
                            to: { transform: 'rotate(360deg)' }
                        }
                    }} />
                </Link>}
                {(!initialParametersState || initialParametersState.length === 0) && <Link
                    component="button"
                    sx={{
                        float: 'right', fontSize: '.9rem', color: 'gray',
                        display: 'block'
                    }}
                    onClick={async () => {
                        let input = prompt('Input your query (can include code, JSON, XML, etc.):') ?? undefined;
                        input = input?.trim();
                        if (input && input !== '') {
                            if (input.startsWith('{') && input.endsWith('}')) {
                                try {
                                    let schema = JSON.parse(input);
                                    let parameters = ParameterUtil.parametersFromJSONSchema(schema);
                                    setInitialParametersState(parameters);
                                    return;
                                } catch (error) { }
                            }

                            setIsGenerating(true);

                            try {
                                let parser: JSONParser = new JSONParser();
                                let result: { [key: string]: any } = {};
                                parser!.onValue = ({ key, value, stack }) => {
                                    Object.assign(result, stack[1]?.value as any);
                                };
                                let stream = await ToolbaseApi.chat.completions(ToolbaseApi.auth.getUser()!.email, 'JSON Schema Generator', [{ role: 'user', content: input }]);
                                for await (const chunk of stream!) {
                                    parser.write(chunk);

                                    const parameters = ParameterUtil.parametersFromJSONSchema((!result.type ? { type: ParameterType.OBJECT } : result) as JSONSchema7);
                                    let latestParameter = parameters[parameters.length - 1];

                                    setInitialParametersState(parameters);
                                }
                            } catch (e) {
                                console.error(e);
                                alert('Invalid JSON Schema or code');
                            } finally {
                                setIsGenerating(false);
                            }
                        }
                    }}
                >
                    Generate Parameters
                </Link>}
            </DialogTitle>
            <DialogContent>
                {initialParametersState && <ParameterEditorInner
                    initialParameters={initialParametersState}
                    onChange={setInitialParametersState}
                    isInput={isInput}
                />}
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{ ml: 2 }}
                                checked={initialParametersState !== undefined}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setInitialParametersState(initialParameters ?? []);
                                    } else {
                                        setInitialParametersState(undefined);
                                    }
                                }}
                            />
                        }
                        label={isInput ? 'Add structured input parameters' : 'Add structured output parameters'}
                    />
                </Box>
                <Button onClick={() => onClose(initialParametersState)} sx={{ mr: 1 }}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ParameterEditor;

