import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { AgentDto } from '../api/dtos/agent.interface';
import { PromptDto } from '../api/dtos/prompt.interface';
import { ToolbaseApi } from '../api/toolbase.api';


interface PromptSelectorDialogProps {
    open: boolean;
    onClose: (namespace?: string, agent?: string, promptVersion?: string, caliber?: 'GOLD' | 'SILVER' | 'BRONZE') => void;
}

const PromptSelectorDialog: React.FC<PromptSelectorDialogProps> = ({
    open,
    onClose
}) => {
    const [agents, setAgents] = React.useState<AgentDto[]>([]);
    const [promptVersions, setPromptVersions] = React.useState<PromptDto[]>([]);

    const [agent, setAgent] = React.useState('');
    const [promptVersion, setPromptVersion] = React.useState('');
    const [caliber, setCaliber] = React.useState<'GOLD' | 'SILVER' | 'BRONZE'>('GOLD');

    React.useEffect(() => {
        const loadAgents = async () => {
            const agents = await ToolbaseApi.agents.list();
            setAgents(agents);
        };
        loadAgents();
    }, []);

    React.useEffect(() => {
        const loadVersions = async () => {
            if (agent) {
                const versions = await ToolbaseApi.agents.listPromptVersions(ToolbaseApi.auth.getUser()!.email, agent);
                setPromptVersions(versions);
            }
        };
        loadVersions();
    }, [agent]);


    const handleSubmit = () => {
        onClose(ToolbaseApi.auth.getUser()!.email, agent, promptVersion, caliber);
    };

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
            <DialogTitle>Select Prompt</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                    <FormControl fullWidth>
                        <Select
                            value={agent}
                            onChange={(e) => {
                                setAgent(e.target.value);
                                setPromptVersion('');
                            }}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select Agent</MenuItem>
                            {agents.map((a) => (
                                <MenuItem key={a.name} value={a.name}>{a.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth disabled={!agent}>
                        <Select
                            value={promptVersion}
                            onChange={(e) => setPromptVersion(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select Version</MenuItem>
                            {promptVersions.map((version) => (
                                <MenuItem key={version.__version} value={version.__version}>
                                    {version.__version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <Select
                            value={caliber}
                            onChange={(e) => setCaliber(e.target.value as 'GOLD' | 'SILVER' | 'BRONZE')}
                        >
                            <MenuItem value="GOLD">Gold</MenuItem>
                            <MenuItem value="SILVER">Silver</MenuItem>
                            <MenuItem value="BRONZE">Bronze</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!agent || !promptVersion}
                        >
                            Select
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PromptSelectorDialog;
