import { Clear } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { JSONParser } from '@streamparser/json';
import { useEffect, useRef, useState } from 'react';
import { DataDto } from '../../api/dtos/data.interface';
import { PromptDto } from '../../api/dtos/prompt.interface';
import { ToolbaseApi } from '../../api/toolbase.api';
import { ParameterUtil } from '../../api/util';
import ChatInput from './chat-input';
import './chat.css';
import { MessageBubble } from './message-bubble';

type ChatState = {
  readonly status: 'idle' | 'waiting' | 'streaming';
  readonly messages: (string | { [key: string]: any })[];
  readonly selectedPrompt?: PromptDto;
  readonly initialInput?: string | { [key: string]: any };
};

type ChatProps = {
  prompt: PromptDto,
  onNewData?: (version: string | undefined, data: Pick<DataDto, 'inputData' | 'outputData' | 'reasoning' | 'caliber' | 'tags'>) => void,
  messageStream?: (messages: (string | { [key: string]: any })[] | undefined) => void,
  evaluating?: boolean,
  messages?: (string | { [key: string]: any })[],
};

function Chat(props: ChatProps) {
  const [state, setState] = useState<ChatState>({ status: 'idle', messages: props.messages ?? [] });
  const streamHandler = useRef({ stopStream: false, stopScroll: false, isProgrammaticScroll: false });

  const currentDataVersion = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (props.messages) {
      const history = props.messages.slice(0, props.messages.length - (props.messages.length % 2));
      const lastMessage = history.length !== props.messages.length ? props.messages[props.messages.length - 1] : undefined;
      setState(prevState => ({ ...prevState, messages: history, initialInput: lastMessage }));
      document.getElementsByClassName('chat-content')[0].scrollTop = document.getElementsByClassName('chat-content')[0].scrollHeight;
    }
  }, [props.messages]);

  const onScroll = () => {
    if (!streamHandler.current.isProgrammaticScroll) {
      if (!streamHandler.current.stopScroll) {
        streamHandler.current.stopScroll = true;
      }
    }
    streamHandler.current.isProgrammaticScroll = false;
  };

  const newMessage = async (msg: string | { [key: string]: any }) => {
    if (state.status !== 'idle') throw new Error('Request already in progress.');
    let history = state.messages;
    let messages: (string | { [key: string]: any })[] = [...state.messages, msg, '...'];

    streamHandler.current.stopStream = false;
    streamHandler.current.stopScroll = false;

    // setStreamHandler({ stopStream: false, stopScroll: false });
    setState({ ...state, status: 'waiting', messages: messages });
    let stream = await ToolbaseApi.chat.completions(
      props.prompt.agentNamespace,
      props.prompt.agentName,
      [
        ...history.map((m, i) => ({
          role: i % 2 === 0 ? 'user' as const : 'assistant' as const,
          content: typeof m === 'string' ? m : JSON.stringify(m)
        })),
        {
          role: 'user' as const,
          content: typeof msg === 'string' ? msg : JSON.stringify(msg)
        }
      ],
      props.prompt.__version
    );

    currentDataVersion.current = stream.dataVersion;

    setState({ ...state, status: 'streaming' });
    let result: string | { [key: string]: any };
    let parser: JSONParser;

    if (stream.type !== 'TEXT') {
      result = {};
      parser = new JSONParser();
      parser!.onValue = ({ key, value, stack }) => {
        // console.log(key, value, JSON.stringify(stack[1], null, 2));
        Object.assign(result, stack[1]?.value as any);
        // console.log(result);
        // setValue(result as Record<string, any>, key, value!, stack, stack[stack.length - 1]?.mode);
        // console.log(result);
      };
    } else {
      result = '';
    }

    // const setValue = (object: Record<string, any>, key: JsonKey, value: JsonPrimitive | JsonStruct, stack: StackElement[], parentType?: 0 | 1) => {
    //   if (stack.length > 1) {
    //     let parentKey = stack[stack.length - 1]?.key!;
    //     if (!object[parentKey]) {
    //       object[parentKey] = stack[stack.length - 1]?.mode === 0 ? {} : [];
    //     }
    //     setValue(object[parentKey], key, value, stack.slice(0, stack.length - 1), stack[stack.length - 1]?.mode);
    //   } else if (stack.length === 1) {
    //     if (parentType === undefined || parentType === 0) {
    //       //  console.log('setting', value);
    //       object[key!] = value;
    //     } else if (parentType === 1) {
    //       // console.log('pushing', value);
    //       object.push(value);
    //     }
    //   } else {
    //     // console.log('done', value);
    //     object = value as JsonStruct;
    //   }
    // };

    let backup = '';
    for await (let chunk of stream) {
      if (streamHandler.current.stopStream) {
        setState({ ...state, status: 'idle', messages: messages.slice(0, -2) });
        props.messageStream?.(messages.slice(0, -2));
        stream.cancel();
        return;
      }

      if (stream.type === 'JSON' || stream.type === 'TOOL') {
        try {
          backup += chunk;
          parser!.write(chunk);
        } catch (error) {
          console.error('Error parsing JSON:', error);
          result = backup;
          backup = '';
          stream.type = 'TEXT';
        }
      } else if (stream.type === 'TEXT') {
        result += chunk;
      } else {
        throw new Error('Invalid stream type.');
      }

      messages[messages.length - 1] = result;
      props.messageStream?.(messages);

      setState({ ...state, status: 'streaming', messages: messages });
      if (!streamHandler.current.stopScroll) {
        streamHandler.current.isProgrammaticScroll = true;
        try {
          document.getElementsByClassName('chat-content')[0].scrollTop = document.getElementsByClassName('chat-content')[0].scrollHeight;
        } catch (e) {
          // ignore
        }
      }
    }

    if (stream.type === 'JSON' || stream.type === 'TOOL') {
      messages[messages.length - 1] = JSON.parse(backup);
    }

    setState({ ...state, status: 'idle', messages: messages });
    if (!streamHandler.current.stopScroll) {
      setTimeout(() => {
        streamHandler.current.isProgrammaticScroll = true;
        try {
          document.getElementsByClassName('chat-content')[0].scrollTop = document.getElementsByClassName('chat-content')[0].scrollHeight;
        } catch (e) {
          // ignore
        }
      }, 5)
    }
  }

  const _stop = () => {
    if (state.status !== 'idle') {
      streamHandler.current.stopStream = true;
      streamHandler.current.stopScroll = true;
    }
  }

  const saveData = () => {
    if (state.messages.length > 1 && state.status === 'idle') {
      const data: Pick<DataDto, 'inputData' | 'outputData' | 'reasoning' | 'caliber' | 'tags'> = {
        caliber: 'GOLD',
        outputData: typeof state.messages[state.messages.length - 1] === 'string' ? state.messages[state.messages.length - 1] as string : JSON.stringify(state.messages[state.messages.length - 1]),
        inputData: typeof state.messages[0] === 'string' ? state.messages[0] as string : JSON.stringify(state.messages[0]),
        reasoning: [],
        tags: ''
      };
      setState({ ...state, messages: [] });
      props.onNewData?.(currentDataVersion.current, data);
    }
  }

  const saveRef = useRef(saveData);

  useEffect(() => {
    saveRef.current = saveData;
  }, [saveData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        saveRef.current();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (
    <Box component="main" sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    }}>
      <Box sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 1
      }}>
        <IconButton
          onClick={() => {
            setState({ ...state, messages: [] });
            props.messageStream?.([]);
          }}
          disabled={state.status !== 'idle' || state.messages.length === 0}
          size="small"
        >
          <Clear />
        </IconButton>
      </Box>
      <Box className='chat-content' onScroll={onScroll} sx={{
        flexGrow: 1,
        overflowY: 'auto',
        mb: 2,
        p: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 1,
        position: 'relative',
      }}>
        {state.messages.map((m, i) => <MessageBubble
          key={i}
          content={m}
          isStreaming={i === state.messages.length - 1 && state.status === 'streaming'}
          contentFormat={i % 2 === 1 ? ParameterUtil.parametersToJSONSchema(props.prompt.outputParameters ?? []) : ParameterUtil.parametersToJSONSchema(props.prompt.inputParameters ?? [])}
          editable={state.status === 'idle' && i === state.messages.length - 1}
          onAssistantMessageChange={(value) => {
            let messages = [...state.messages];
            messages[i] = value;
            setState({ ...state, messages: messages });
          }}
        />
        )}
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#ffffff',
      }}>
        <ChatInput
          onNewMessage={newMessage}
          onStop={_stop}
          isIdle={state.status === 'idle'}
          inputSchema={state.messages.length > 1 ? undefined : ParameterUtil.parametersToJSONSchema(props.prompt.inputParameters)}
          evaluating={props.evaluating && state.messages.length > 1}
          initialInput={state.initialInput ?? (props.prompt.inputParameters && state.messages.length < 2 ? {} : '')}
          onSave={saveData}
        />
      </Box>
    </Box>

  );
}

export default Chat;
