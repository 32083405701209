import { JSONSchema7 } from "json-schema";
import { ParameterDto, ParameterRagType, ParameterType } from "./dtos/parameter.interface";

const parametersToJSONSchema = (schema: JSONSchema7, parameters: ParameterDto[], optionStrict: boolean = false) => {
    parameters.forEach((param: ParameterDto) => {
        schema.required = schema.required ?? [];
        if (param.isRequired || optionStrict) {
            schema.required.push(param.name);
        }

        schema.properties = schema.properties ?? {};
        schema.properties[param.name] = {
            type: param.type === ParameterType.ENUM ? undefined : param.type === ParameterType.DATE ? (param.isRequired ? 'string' : optionStrict ? ['string', 'null'] : 'string') : param.isRequired ? param.type : optionStrict ? [param.type, 'null'] : param.type,
            description: param.description
        };

        if (param.isArray) {
            (schema.properties[param.name] as JSONSchema7).type = param.isRequired ? 'array' : optionStrict ? ['array', 'null'] : 'array';
            (schema.properties[param.name] as JSONSchema7).items = {
                type: param.type === ParameterType.ENUM ? undefined : param.type === ParameterType.DATE ? (param.isRequired ? 'string' : optionStrict ? ['string', 'null'] : 'string') : param.isRequired ? param.type : optionStrict ? [param.type, 'null'] : param.type,
            };

            if (param.type === ParameterType.DATE) {
                ((schema.properties[param.name] as JSONSchema7).items as JSONSchema7).format = 'date-time';
            } else if (param.type === ParameterType.OBJECT && param.subProperties && param.subProperties.length > 0) {
                (schema.properties[param.name] as JSONSchema7).items = parametersToJSONSchema((schema.properties[param.name] as JSONSchema7).items as JSONSchema7, param.subProperties, optionStrict);
            } else if (param.type === ParameterType.ENUM && param.subProperties && param.subProperties.length > 0) {
                (schema.properties[param.name] as JSONSchema7).items = { oneOf: param.subProperties.map((subParam) => ({ const: subParam.name, description: subParam.description })) };
                if (!param.isArray && !param.isRequired && optionStrict) {
                    ((schema.properties[param.name] as JSONSchema7).items as JSONSchema7).oneOf?.push({ const: null, description: 'No value' });
                }
            }
        } else if (param.type === ParameterType.DATE) {
            (schema.properties[param.name] as JSONSchema7).format = 'date-time';
        } else if (param.type === ParameterType.OBJECT && param.subProperties && param.subProperties.length > 0) {
            schema.properties[param.name] = parametersToJSONSchema(schema.properties[param.name] as JSONSchema7, param.subProperties, optionStrict);
        } else if (param.type === ParameterType.ENUM && param.subProperties && param.subProperties.length > 0) {
            (schema.properties[param.name] as JSONSchema7).oneOf = param.subProperties?.map((subParam) => ({ const: subParam.name, description: subParam.description }));
            if (!param.isArray && !param.isRequired && optionStrict) {
                ((schema.properties[param.name] as JSONSchema7).oneOf as JSONSchema7[]).push({ const: null, description: 'No value' });
            }
        }
    });

    if (schema.required?.length === 0) {
        delete schema.required;
    }

    return schema;
};

const parametersFromJSONSchema = (schema: JSONSchema7): ParameterDto[] => {
    if (schema.type === ParameterType.OBJECT || (schema.type === 'array' && schema.items && (schema.items as JSONSchema7).type === ParameterType.OBJECT)) {
        return Object.entries(schema.properties ?? (schema.items as JSONSchema7)?.properties ?? {}).map(([name, property]) => {
            let type = (property as JSONSchema7).type === 'array' ? ((property as JSONSchema7).items as JSONSchema7)?.type as ParameterType : (property as JSONSchema7).type as ParameterType;
            if (type === 'string' && (property as JSONSchema7).format === 'date-time') {
                type = ParameterType.DATE;
            }

            let subProperties: ParameterDto[] | undefined;
            if ((property as JSONSchema7).type === 'object' || ((property as JSONSchema7).type === 'array' && ((property as JSONSchema7).items as JSONSchema7)?.type === 'object')) {
                subProperties = parametersFromJSONSchema(property as JSONSchema7);
            } else if (!type && ((property as JSONSchema7).oneOf || ((property as JSONSchema7).items as JSONSchema7)?.oneOf)) {
                type = ParameterType.ENUM;
                subProperties = ((property as JSONSchema7).oneOf ?? ((property as JSONSchema7).items as JSONSchema7)?.oneOf!).map((option) => ({
                    name: (option as JSONSchema7).const as string,
                    description: (option as JSONSchema7).description ?? '',
                    type: ParameterType.STRING,
                    isRequired: false,
                    isArray: false,
                    ragType: ParameterRagType.NONE,
                }));
            }

            return {
                name,
                description: (property as JSONSchema7).description ?? '',
                type,
                isRequired: schema.type === 'array' ? ((schema.items as JSONSchema7).required?.includes(name) ?? false) : (schema.required?.includes(name) ?? false),
                isArray: (property as JSONSchema7).type === 'array',
                ragType: ParameterRagType.NONE,
                subProperties
            };
        });
    } else {
        throw new Error('Invalid schema');
    }
}

export const ParameterUtil = {
    parametersToJSONSchema: (parameters?: ParameterDto[]) => {
        return parameters && parameters.length > 0 ? parametersToJSONSchema({
            type: "object",
        }, parameters) : undefined;
    },
    parametersFromJSONSchema
};